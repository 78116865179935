<template>
  <div>
    <md-table md-card table-header-color="blue" :value="quests">
      <md-table-toolbar>
        <div class="md-toolbar-section-start">
          <h1 class="md-title">Настройка квестов</h1>
        </div>
      </md-table-toolbar>

      <md-table-empty-state md-label="Список пуст" />

      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="ID">
          {{ item.questId }}
        </md-table-cell>

        <md-table-cell md-label="Название">
          {{ item.name }}
        </md-table-cell>

        <md-table-cell md-label="Значение">
          {{ item.value }}
        </md-table-cell>

        <md-table-cell md-label="Тип">
          {{ typesById[item.questTypeId] }}
        </md-table-cell>

        <md-table-cell md-label="Сложность">
          {{ compexitiesById[item.questComplexityId] }}
        </md-table-cell>

        <md-table-cell md-label="Периодичность">
          {{ intensitiesById[item.questIntensityId] }}
        </md-table-cell>

        <md-table-cell md-label="Роль">
          {{ professionsById[item.professionId] || "" }}
        </md-table-cell>

        <md-table-cell>
          <md-button
            class="md-just-icon md-simple md-primary"
            @click="openItemInModal(item)"
          >
            <md-icon>edit</md-icon>
            <md-tooltip md-direction="left">Изменить</md-tooltip>
          </md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>

    <QuestsModal
      v-if="showModal && !!selectedItem"
      :item="selectedItem"
      @close="closeModal"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
  components: {
    QuestsModal: () => import("@/components/Modals/QuestsModal"),
  },

  data() {
    return {
      selectedItem: null,
      showModal: false,
    };
  },

  computed: {
    ...mapGetters("professions", ["professionsById"]),
    ...mapGetters("quests", [
      "compexitiesById",
      "intensitiesById",
      "typesById",
    ]),
    ...mapState("quests", ["quests"]),
  },

  async mounted() {
    this.showLoader(true);
    await this.getProfessions();
    await this.getQuestsData();
    this.showLoader(false);
  },

  methods: {
    ...mapActions("professions", ["getProfessions"]),
    ...mapActions("quests", ["getQuestsData"]),
    ...mapMutations({
      showLoader: "SET_SHOW_LOADER",
    }),

    async closeModal(needUpdate) {
      this.showModal = false;
      this.selectedItem = null;

      if (needUpdate == true) {
        this.showLoader(true);
        await this.getQuestsData();
        this.showLoader(false);
      }
    },

    openItemInModal(item) {
      this.selectedItem = item;
      this.showModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .md-table-cell-container {
    white-space: nowrap;
  }
}
</style>
